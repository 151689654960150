import map from 'lodash/map'
import join from 'lodash/join'
import find from 'lodash/find'

function toFloat2Dec (val) {
  return Number(parseFloat(val).toFixed(2))
}

export default {
  data () {
    return {
      product: require('@/database/products/tvpre/decoder')
    }
  },
  mounted () {
    if (!this.$route.meta.promo) return

    switch (this.$route.meta.promo.name) {
      case 'promokit':
      case 'promokitcanais':
        this.product = require('@/database/products/tvpre/promos/promokitcanais')
        break
      case 'promoreceptorhd':
      case 'promoreceptorhdcanais':
        this.product = require('@/database/products/tvpre/promos/promoreceptorhdcanais')
        break
      case 'promoreceptorhdcanaisdesconto':
        this.product = require('@/database/products/tvpre/promos/promoreceptorhdcanaisdesconto')
        break
      case 'vtxantenareceptorhdnotsulsudeste':
        this.product = require('@/database/products/tvpre/promos/promokitcanaisnotsulsudeste')
        break
      case 'promokitcanaisboletoperdido':
        this.product = require('@/database/products/tvpre/promos/promokitcanaisboletoperdido')
        break
    }
  },

  methods: {
    getProduct (id, param) {
      if (id && !param) return this.product[id]
      if (id && param) return this.product[id][param]
      return Object.keys(this.product)
    },
    getUFArray (promo) {
      return join(map(promo.ufs, 'uf'), ', ')
    },
    getUFIndex (ufs, uf) {
      return find(ufs, (o) => {
        return o.uf === uf
      })
    },
    calculatedTotal (pontoAdicional) {
      if (pontoAdicional) return String(toFloat2Dec(toFloat2Dec(this.payload.pedido.produto.map(o => o.preco * o.quantidade).reduce((a, b) => a + b))))

      return String(toFloat2Dec(toFloat2Dec(this.payload.pedido.produto[0].preco) * toFloat2Dec(this.payload.pedido.produto[0].quantidade)) + (this.payload.consumidor.endereco.cep ? this.payload.pedido.postagem.valorEntrega : 0))
    },
    calculatedDiscountedPrice (price, discount) {
      return String(toFloat2Dec(toFloat2Dec(price) - toFloat2Dec(discount)))
    }

  }
}
