module.exports = {
  vtxreceptorhd: {
    index: 1,
    best: true,
    plan: 'vtxreceptorhd',
    sku: 'sku_vtxreceptorhd',
    title: {
      id: 'HD',
      short: 'Receptor HD + 2 meses de canais abertos*',
      nome: 'Receptor Claro tv HD'
    },
    price: {
      default: '99.00',
      split: ['99', '00']
    },
    frete: 0,
    cover: [
      require('@/assets/images/product/tvpre/VTXN/receptor-hd-claro-tv-pre.png')
    ],
    subheading: [
      '* Programação pacote inicial'
    ],
    info: {
      tipo: 'Assista suas séries favoritas na melhor qualidade de imagem e som.'
    },
    checkout: {
      upnid: {
        cartaodecredito: {
          development: [
            'p33553?p=wq4b3&off=r34vq',
            'p33553?p=wq4b3&off=17by7',
            'p33553?p=wq4b3&off=vy21q'
          ],
          homologation: [
            'p33554?p=2xbmk&off=ljl6y',
            'p33554?p=2xbmk&off=wqpvj',
            'p33554?p=2xbmk&off=2x4y1'
          ],
          production: [
            'p33580?p=mvxn8&off=2x4b1',
            'p33580?p=mvxn8&off=yw64o',
            'p33580?p=mvxn8&off=xm5bv'
          ]
        },
        boletobancario: {
          development: [
            'p33553?p=wq4b3&off=noj58',
            'p33553?p=wq4b3&off=57wkl',
            'p33553?p=wq4b3&off=jenwr'
          ],
          homologation: [
            'p33554?p=2xbmk&off=zxnqq',
            'p33554?p=2xbmk&off=6o2yl',
            'p33554?p=2xbmk&off=noj68'
          ],
          production: [
            'p33580?p=mvxn8&off=pbvxk',
            'p33580?p=mvxn8&off=ob1n6',
            'p33580?p=mvxn8&off=ekwvx'
          ]
        }
      }
    }
  },

  vtxantenareceptorhd: {
    index: 2,
    plan: 'vtxantenareceptorhd',
    sku: 'sku_vtxantenareceptorhd',
    title: {
      id: 'KITHD',
      short: 'Kit Antena e Receptor HD + 2 meses de canais abertos*',
      nome: 'Antena e Receptor Claro tv HD'
    },
    price: {
      default: '169.00',
      split: ['169', '00']
    },
    frete: 0,
    cover: [
      require('@/assets/images/product/tvpre/VTXN/antena-receptor-hd-claro-tv-pre.png')
    ],
    info: {
      tipo: 'Assista suas séries favoritas na melhor qualidade de imagem e som.'
    },
    promo: {
      ufs: [
        { uf: 'AL', discount: '0' },
        { uf: 'BA', discount: '0' },
        { uf: 'CE', discount: '0' },
        { uf: 'DF', discount: '0' },
        { uf: 'ES', discount: '0' },
        { uf: 'GO', discount: '0' },
        { uf: 'MA', discount: '0' },
        { uf: 'MS', discount: '0' },
        { uf: 'MG', discount: '0' },
        { uf: 'PB', discount: '0' },
        { uf: 'PR', discount: '0' },
        { uf: 'PE', discount: '0' },
        { uf: 'PI', discount: '0' },
        { uf: 'RJ', discount: '0' },
        { uf: 'RN', discount: '0' },
        { uf: 'RS', discount: '0' },
        { uf: 'SC', discount: '0' },
        { uf: 'SP', discount: '0' },
        { uf: 'SE', discount: '0' },
        { uf: 'TO', discount: '0' }
      ],
      subheading: []
    },
    subheading: [
      '* Programação pacote inicial',
      '* Produto disponível para os estados: '
    ],
    checkout: {
      upnid: {
        cartaodecredito: {
          development: [
            'p34097?p=jew2r&off=7v2xj',
            'p34097?p=jew2r&off=483qk',
            'p34097?p=jew2r&off=3b454'
          ],
          homologation: [
            'p34098?p=17y67&off=82pj5',
            'p34098?p=17y67&off=vybrv',
            'p34098?p=17y67&off=bmx31'
          ],
          production: [
            'p34095?p=ekv5x&off=82p55',
            'p34095?p=ekv5x&off=obxo3',
            'p34095?p=ekv5x&off=57kq5'
          ]
        },
        boletobancario: {
          development: [
            'p34097?p=jew2r&off=qkxeq',
            'p34097?p=jew2r&off=483qk',
            'p34097?p=jew2r&off=3b454'
          ],
          homologation: [
            'p34098?p=17y67&off=wq4r7',
            'p34098?p=17y67&off=vybrv',
            'p34098?p=17y67&off=bmx31'
          ],
          production: [
            'p34095?p=ekv5x&off=ywkrv',
            'p34095?p=ekv5x&off=eken6',
            'p34095?p=ekv5x&off=jexm8'
          ]
        }
      }
    }
  },

  vtxantenareceptorhddoisanoscanaisabertos: {
    index: 3,
    plan: 'vtxantenareceptorhddoisanoscanaisabertos',
    sku: 'sku_vtxantenareceptorhddoisanoscanaisabertos',
    title: {
      id: 'KITHDDOISANOSCANAISABERTOS',
      short: 'Kit Antena e Receptor HD + 2 anos de canais abertos*',
      nome: 'Antena e Receptor Claro tv HD + 2 anos de canais abertos'
    },
    price: {
      default: '499.00',
      split: ['499', '00']
    },
    frete: 0,
    cover: [
      require('@/assets/images/product/tvpre/VTXN/antena-receptor-hd-claro-tv-pre.png')
    ],
    info: {
      tipo: 'Assista suas séries favoritas na melhor qualidade de imagem e som.'
    },
    promo: {
      ufs: [
        { uf: 'AL', discount: '0' },
        { uf: 'BA', discount: '0' },
        { uf: 'CE', discount: '0' },
        { uf: 'DF', discount: '0' },
        { uf: 'ES', discount: '0' },
        { uf: 'GO', discount: '0' },
        { uf: 'MA', discount: '0' },
        { uf: 'MS', discount: '0' },
        { uf: 'MG', discount: '0' },
        { uf: 'PB', discount: '0' },
        { uf: 'PR', discount: '0' },
        { uf: 'PE', discount: '0' },
        { uf: 'PI', discount: '0' },
        { uf: 'RJ', discount: '0' },
        { uf: 'RN', discount: '0' },
        { uf: 'RS', discount: '0' },
        { uf: 'SC', discount: '0' },
        { uf: 'SP', discount: '0' },
        { uf: 'SE', discount: '0' },
        { uf: 'TO', discount: '0' }
      ],
      subheading: []
    },
    subheading: [
      '* Programação pacote inicial',
      '* Produto disponível para os estados: '
    ],
    checkout: {
      upnid: {
        cartaodecredito: {
          development: [
            'p34097?p=jew2r&off=7v2xj',
            'p34097?p=jew2r&off=483qk',
            'p34097?p=jew2r&off=3b454'
          ],
          homologation: [
            'p34098?p=17y67&off=82pj5',
            'p34098?p=17y67&off=vybrv',
            'p34098?p=17y67&off=bmx31'
          ],
          production: [
            'p34095?p=ekv5x&off=82p55',
            'p34095?p=ekv5x&off=obxo3',
            'p34095?p=ekv5x&off=57kq5'
          ]
        },
        boletobancario: {
          development: [
            'p34097?p=jew2r&off=qkxeq',
            'p34097?p=jew2r&off=483qk',
            'p34097?p=jew2r&off=3b454'
          ],
          homologation: [
            'p34098?p=17y67&off=wq4r7',
            'p34098?p=17y67&off=vybrv',
            'p34098?p=17y67&off=bmx31'
          ],
          production: [
            'p34095?p=ekv5x&off=ywkrv',
            'p34095?p=ekv5x&off=eken6',
            'p34095?p=ekv5x&off=jexm8'
          ]
        }
      }
    }
  },

  vtxreceptorhddoisanoscanaisabertos: {
    index: 4,
    plan: 'vtxreceptorhddoisanoscanaisabertos',
    sku: 'sku_vtxreceptorhddoisanoscanaisabertos',
    title: {
      id: 'HDDOISANOSCANAISABERTOS',
      short: 'Receptor HD + 2 anos de canais abertos*',
      nome: 'Receptor Claro tv HD + 2 anos de canais abertos'
    },
    price: {
      default: '399.00',
      split: ['399', '00']
    },
    frete: 0,
    cover: [
      require('@/assets/images/product/tvpre/VTXN/receptor-hd-claro-tv-pre.png')
    ],
    subheading: [
      '* Programação pacote inicial'
    ],
    info: {
      tipo: 'Assista suas séries favoritas na melhor qualidade de imagem e som.'
    },
    checkout: {
      upnid: {
        cartaodecredito: {
          development: [
            'p33553?p=wq4b3&off=r34vq',
            'p33553?p=wq4b3&off=17by7',
            'p33553?p=wq4b3&off=vy21q'
          ],
          homologation: [
            'p33554?p=2xbmk&off=ljl6y',
            'p33554?p=2xbmk&off=wqpvj',
            'p33554?p=2xbmk&off=2x4y1'
          ],
          production: [
            'p33580?p=mvxn8&off=2x4b1',
            'p33580?p=mvxn8&off=yw64o',
            'p33580?p=mvxn8&off=xm5bv'
          ]
        },
        boletobancario: {
          development: [
            'p33553?p=wq4b3&off=noj58',
            'p33553?p=wq4b3&off=57wkl',
            'p33553?p=wq4b3&off=jenwr'
          ],
          homologation: [
            'p33554?p=2xbmk&off=zxnqq',
            'p33554?p=2xbmk&off=6o2yl',
            'p33554?p=2xbmk&off=noj68'
          ],
          production: [
            'p33580?p=mvxn8&off=pbvxk',
            'p33580?p=mvxn8&off=ob1n6',
            'p33580?p=mvxn8&off=ekwvx'
          ]
        }
      }
    }
  }
}
