module.exports = {
  vtxreceptorhd: {
    index: 2,
    best: true,
    plan: 'vtxreceptorhd',
    sku: 'sku_vtxreceptorhd',
    title: {
      id: 'HD',
      short: 'Receptor HD',
      nome: 'Receptor Claro tv HD'
    },
    price: {
      default: '99.00',
      split: ['99', '00']
    },
    cover: [
      require('@/assets/images/product/tvpre/VTXN/receptor-hd-claro-tv-pre-um.jpg')
    ],
    info: {
      tipo: 'Assista suas séries favoritas na melhor qualidade de imagem e som.'
    },
    checkout: {
      upnid: {
        cartaodecredito: {
          development: ['p33553?p=wq4b3&off=r34vq', 'p33553?p=wq4b3&off=17by7', 'p33553?p=wq4b3&off=vy21q'],
          homologation: ['p33554?p=2xbmk&off=ljl6y', 'p33554?p=2xbmk&off=wqpvj', 'p33554?p=2xbmk&off=2x4y1'],
          production: ['p33580?p=mvxn8&off=2x4b1', 'p33580?p=mvxn8&off=yw64o', 'p33580?p=mvxn8&off=xm5bv']
        },
        boletobancario: {
          development: ['p33553?p=wq4b3&off=noj58', 'p33553?p=wq4b3&off=57wkl', 'p33553?p=wq4b3&off=jenwr'],
          homologation: ['p33554?p=2xbmk&off=zxnqq', 'p33554?p=2xbmk&off=6o2yl', 'p33554?p=2xbmk&off=noj68'],
          production: ['p33580?p=mvxn8&off=pbvxk', 'p33580?p=mvxn8&off=ob1n6', 'p33580?p=mvxn8&off=ekwvx']
        }
      }
    }
  }
}
