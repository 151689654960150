module.exports = {
  vtxantenareceptorhd: {
    index: 1,
    best: true,
    plan: 'vtxantenareceptorhd',
    sku: 'sku_vtxantenareceptorhdboletoperdido',
    title: {
      id: 'KITHD',
      short: 'Kit Antena e Receptor HD*',
      nome: 'Antena e Receptor Claro tv HD'
    },
    price: {
      default: '169.00',
      split: ['169', '00']
    },
    cover: [
      require('@/assets/images/product/tvpre/VTXN/antena-receptor-hd-claro-tv-pre.png')
    ],
    info: {
      tipo: 'Assista suas séries favoritas na melhor qualidade de imagem e som.'
    },
    promo: {
      ufs: [
        { uf: 'AL', discount: '0' },
        { uf: 'BA', discount: '0' },
        { uf: 'CE', discount: '0' },
        { uf: 'DF', discount: '0' },
        { uf: 'ES', discount: '0' },
        { uf: 'GO', discount: '0' },
        { uf: 'MA', discount: '0' },
        { uf: 'MS', discount: '0' },
        { uf: 'MG', discount: '0' },
        { uf: 'PB', discount: '0' },
        { uf: 'PR', discount: '0' },
        { uf: 'PE', discount: '0' },
        { uf: 'PI', discount: '0' },
        { uf: 'RJ', discount: '0' },
        { uf: 'RN', discount: '0' },
        { uf: 'RS', discount: '0' },
        { uf: 'SC', discount: '0' },
        { uf: 'SP', discount: '0' },
        { uf: 'SE', discount: '0' },
        { uf: 'TO', discount: '0' }
      ],
      subheading: [
        'Produto não disponível para as regiões <span class="red--text"> Sul e Sudeste, </span> com opção de parcelamento em <span class="red--text"> 10x</span> de <span class="red--text">R$ 22,00</span>',
        'Oferta com <span class="red--text">Antena Grátis</span> por tempo limitado'
      ]
    },
    subheading: [
      '* Produto não disponível para as regiões Sul e Sudeste, com opção de parcelamento em 10x de R$ 22,00',
      '* Promoção com antena grátis por tempo limitado'
    ]
  }
}
